import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout className='font-sans'>
    <section className='h-96'>
      <p className='p-8 text-5xl text-center'>Thank you!</p>
      <p className='p-8 text-4xl text-center'>
        Your submission has been submitted successfully.
      </p>
    </section>
  </Layout>
);
